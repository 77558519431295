#NotificationWebFormScreenContainer {
    height: 100%;
}

#NotificationWebFormScreenContainer textarea, #NotificationWebFormScreenContainer input {
    box-sizing: border-box;
    padding: 8px 12px;
    border-radius: 6px;
    border: none;
    font: inherit;
}

#NotifactionWebFormScrollableContainer {
    background-color: #424756;
    width: 100%;
    min-height: 100%;
    padding: 84px 48px 84px;
    column-gap: 12px;
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    row-gap: 12px;
    align-items: center;
}

#NotifactionWebFormScrollableContainer input[type="file" i] {
    color: white;
    padding: 8px 0px;
}

#NotifactionWebFormScrollableContainer p {
    color: white;
}

#NotifactionWebFormScrollableContainer > * {
    max-width: 450px;
    width: 100%;
}