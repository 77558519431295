#TncContainer {
    padding: 64px 24px 24px;
    display: flex;
    flex-direction: column;
    row-gap: 16px;
}

#TncContainer p {
    text-indent: 32px;
}

#TncContainer h3 {
    margin: 0;
    text-align: start;
}