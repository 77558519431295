#HomeScreenContainer {
    background-color: #424756;
    width: 100%;
    height: 100%;
    padding: 100px 24px 24px;
    display: flex;
    flex-flow: row wrap;
    column-gap: 12px;
    box-sizing: border-box;
}

.HomeFeatureButtonContainer {
    border-radius: 10px;
    border: 1px solid white;
    width: calc(20% - 9.6px);
    box-sizing: border-box;
    margin: 0;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px;
    text-align: center;
}

.HomeFeatureButtonContainer > p {
    text-align: center;
    color: white;
}