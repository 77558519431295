#NotificationWebDisplayMainContainer {
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0px !important;
    align-items: center;
}

#NotificationWebDisplayTopAppBar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 52px;
    width: inherit;
    background-color: #6B2C8F;
    padding: 16px;
    align-items: center;
    justify-content: center;
    display: flex;
    box-sizing: border-box;
}

#NotificationWebDisplayBody {
    padding-top: 52px;
    padding-bottom: 88px;
    display: flex;
    flex-direction: column;
    max-width: 500px;
    width: 100%;
}

#NotificationWebDisplayBody > * {
    margin: 0px 16px;
}

#NotificationWebDisplayBody > img {
    width: 100%;
    margin: 0;
}

#NotificationWebDisplayBody > p {
    color: #6B2C8F;
    font-size: 14px;
    text-indent: 24px;
    line-height: 1.2;
    padding-top: 6px;
    padding-bottom: 6px;
    text-align: justify;
}

#NotificationWebDisplayBottomBar {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #6B2C8F;
    box-sizing: border-box;
    display: flex;
    padding: 16px;
    height: 72px;
    align-items: center;
}

#NotificationWebDisplayBottomBar > :nth-child(1) {
    height: 32px;
    flex-shrink: 0;
}

#NotificationWebDisplayBottomBar > :nth-child(2) {
    flex-grow: 1;
}

#NotificationWebDisplayBottomBar > :nth-child(3) {
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    row-gap: 4px;
}

#NotificationWebDisplayBottomBar > :nth-child(3) > :nth-child(1) {
    font-size: 10px;
    color: white;
}

#NotificationWebDisplayBottomBar > :nth-child(3) > :nth-child(2) {
    display: flex;
    flex-direction: row-reverse;
    column-gap: 6px;
}

#NotificationWebDisplayBottomBar > :nth-child(3) img {
    height: 24px;
}

#NotificationWebDisplayErrorContainer {
    height: calc(100vh - 140px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px 24px;
}

#NotificationWebDisplayErrorContainer > :nth-child(1) {
    background-color: red;
    width: 42px;
    height: 42px;
    border-radius: 21px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-weight: bold;
    font-size: 24px;
    margin-bottom: 24px;
}

#NotificationWebDisplayErrorContainer > :nth-child(2) {
    font-size: 14px;
    font-weight: 600;
    width: 100%;
    text-align: center;
    margin-bottom: 24px;
}

#NotificationWebDisplayErrorContainer > :nth-child(3) {
    width: 100%;
}