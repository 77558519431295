#CreateOrderScreenContainer {
    position: relative;
    width: inherit;
    height: 100vh;
    color: white;
    display: flex;
    flex-direction: column;
}

#CreateOrderScreenContainer input, #CreateOrderScreenContainer textarea {
    box-sizing: border-box;
    flex-grow: 1;
    font: inherit;
}

#CreateOrderTextFieldAndButtonContainer {
    display: flex;
    align-items: flex-start;
}

#CreateOrderTextFieldAndButtonContainer > input {
    flex-grow: 1;
}

#CreateOrderTextFieldAndButtonContainer > .SizedDiv {
    flex-shrink: 0;
}

#CreateOrderTextFieldAndButtonContainer > button {
    flex-shrink: 0;
}

.FormButton > .LoadingWidgetContainer > div {
    background-color: black;
}

#CreateOrderScreenContainer > :nth-child(1) {
    position: relative;
    display: flex;
    /* flex-direction: column; */
    align-items: center;
    padding: 84px 0px 84px;
    background-color: #424756;
    flex-grow: 1;

}

#CreateOrderScreenContainer > :nth-child(1) > button {
    position: fixed;
    bottom: 72px;
    right: 32px;
    background-color: white;
    padding: 8px;
    border-radius: 12px;
}

#CreateOrderScreenContainer > :nth-child(1) > button img {
    width: 40px;
    height: 40px;
}

#CreateOrderScreenContainer > :nth-child(1) > button p {
    text-align: center;
    font-size: 11px;
    font-weight: 700;
}

#CreateOrderBottomSection {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    box-sizing: border-box;
    flex-shrink: 0;
    width: inherit;
    background-color: #6B2C8F;
    display: flex;
    padding: 12px 40px;
    z-index: 100;
    height: 60px;
}

#CreateOrderBottomSection > :nth-child(1) {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
}

#CreateOrderBottomSection > :nth-child(1) > :nth-child(1) {
    font-size: 12px;
    font-weight: 600;
}

#CreateOrderBottomSection > :nth-child(1) > :nth-child(2) {
    font-size: 16px;
    font-weight: 700;
    color: #FFCC33;
}

#CreateOrderBottomSection > :nth-child(2) {
    flex-grow: 1;
}

#CreateOrderBottomSection > button {
    flex-shrink: 0;
    background-color: #FFCC33;
    color: #6B2C8F;
}

#CreateOrderFormContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 10px;
    padding: 24px 0px;
}

#CreateOrderFormContainer > * {
    max-width: 500px;
    margin: 0px 24px;
    width: calc(100% - 48px);
    box-sizing: border-box;
}

#CreateOrderMenuSectionContainer {
    max-width: calc(100vw - 48px);
    width: calc(100vw - 48px);
    margin: 0px 24px;
}

#CreateOrderMenuSectionContainer > div {
    padding-top: 12px;
    padding-bottom: 12px;
}

#CreateOrderMenuWrapper {
    display: flex;
    flex-flow: row wrap;
    row-gap: 12px;
    column-gap: 12px;
}

#CreateOrderMenuCellContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    box-sizing: border-box;
    border: 0.5px solid #7b7777;
    border-radius: 8px;
    overflow: hidden;
}

#CreateOrderMenuCellContainer img {
    width: 100%;
    flex-shrink: 0;
}

#CreateOrderMenuCellContainer > :nth-child(2) {
    padding: 8px 8px 0px 8px;
    font-size: 14px;
    font-weight: 600;
    flex-grow: 1;
}

#CreateOrderMenuCellContainer > :nth-child(3) {
    padding: 4px 8px 0px 8px;
    font-size: 13px;
    font-weight: 600;
    color: rgb(247, 114, 154);
    flex-shrink: 0;
}

#CreateOrderMenuCellContainer > :nth-child(4) {
    padding: 12px 8px;
    display: flex;
    justify-content: center;
    column-gap: 12px;
    align-items: center;
    font-size: 12px;
    flex-shrink: 0;
}

#CreateOrderMenuCellContainer > :nth-child(4) > input {
    max-width: 52px;
    min-width: 32px;
    text-align: center;
    flex-grow: 0;
}

#CreateOrderMenuCellContainer button {
    background-color: white;
    height: 24px;
    width: 24px;
    border: none;
    border-radius: 8px;
    flex-shrink: 0;
}

#CreateOrderMenuCategoryTickerContainer {
    display: flex;
    flex-flow: row wrap;
    gap: 8px;
}

#CreateOrderMenuCategoryTickerContainer > button {
    display: flex;
    padding: 6px;
    align-items: center;
    border-radius: 12px;
    font-size: 14px;
}

#CreateOrderMenuCategoryTickerContainer .Active {
    border: 1px solid white;
    background-color: white;
    color: #424756;
    font-weight: 500;
}

#CreateOrderMenuCategoryTickerContainer .Unactive {
    border: 1px solid #9BA0AD;
    color: #9BA0AD;
}

#CreateOrderPopUpConfirmationBackground {
    position: fixed;
    inset: 0px;
    background-color: #00000088;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 101;
}

#CreateOrderPopUpConfirmationBackground > div {
    height: 70%;
    width: 50%;
    max-width: 400px;
    min-height: 300px;
    background-color: white;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    padding: 16px;
    box-sizing: border-box;
}

#CreateOrderPopUpConfirmationBackground > div > :nth-child(1) {
    font-size: 16px;
    font-weight: 700;
    flex-shrink: 0;
    text-align: center;
}

#CreateOrderPopUpMenuList {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    overflow: scroll;
}

#CreateOrderPopUpConfirmationProductItemContainer {
    display: flex;
    column-gap: 8px;
}

#CreateOrderPopUpConfirmationProductItemContainer > img {
    width: 52px;
    height: 52px;
    border-radius: 10px;
    overflow: hidden;
}

#CreateOrderPopUpConfirmationProductItemContainer > :nth-child(2) {
    display: flex;
    flex-direction: column;
    row-gap: 4px;
    justify-content: center;
}

#CreateOrderPopUpConfirmationProductItemContainer > :nth-child(2) > :nth-child(1) {
    font-size: 14px;
    font-weight: 600;
}

#CreateOrderPopUpConfirmationProductItemContainer > :nth-child(2) > :nth-child(2) {
    font-size: 12px;
    font-weight: 600;
}

#CreateOrderPopUpConfirmationProductItemContainer > :nth-child(2) > :nth-child(3) {
    display: flex;
    column-gap: 12px;
    align-items: center;
}

#CreateOrderPopUpConfirmationProductItemContainer > :nth-child(2) > :nth-child(3) > :nth-child(2) {
    width: 24px;
    text-align: center;
}

#CreateOrderPopUpConfirmationProductItemContainer button {
    width: 24px;
    height: 24px;
    border-radius: 8px;
    background-color: #424756;
    color: white;
}

#CreateOrderPopUpPriceCalculation {
    display: flex;
    flex-direction: column;
    row-gap: 2px;
}

#CreateOrderPopUpCtasContainer {
    flex-shrink: 0;
    display: flex;
    column-gap: 16px;
}

#CreateOrderPopUpConfirmationBackground .FormButton {
    flex-grow: 1;
}

#CashierSelectOutletContainer {
    display: flex;
    flex-direction: column;
    row-gap: 12px;
    width: 100%;
    height: 100%;
}

.CreateOrderPopUpPriceComponent {
    display: flex;
}

.CreateOrderPopUpPriceComponent > p:nth-child(1) {
    flex-grow: 1;
}

/* CASHIER */

#CashierScreenContainer {
    position: relative;
    width: inherit;
    height: 100vh;
    color: white;
    display: flex;
    flex-direction: column;
}

#CashierScreenContainer input, #CashierScreenContainer textarea {
    box-sizing: border-box;
    flex-grow: 1;
    font: inherit;
    flex-shrink: 0;
}

#CashierScreenContainer > :nth-child(1) {
    position: relative;
    display: flex;
    align-items: center;
    padding: 52px 0px 60px;
    background-color: #424756;
    flex-grow: 1;
    height: 100%;
    box-sizing: border-box;
}

#CashierScreenContainer > :nth-child(1) > :nth-child(1) {
    flex-grow: 1;
    flex-shrink: 0;
}



#CashierScreenContainer > :nth-child(1) > button {
    position: fixed;
    bottom: 72px;
    right: 32px;
    background-color: white;
    padding: 8px;
    border-radius: 12px;
}

#CashierScreenContainer > :nth-child(1) > button img {
    width: 40px;
    height: 40px;
}

#CashierScreenContainer > :nth-child(1) > button p {
    text-align: center;
    font-size: 11px;
    font-weight: 700;
}

#CashierFormContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 10px;
    padding: 24px 0px;
    overflow-y: scroll;
    height: 100%;
    width: 50vw;
    box-sizing: border-box;
}

#CashierFormContainer::-webkit-scrollbar {
    display: none;
}

#CashierFormContainer {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

#CashierFormContainer > * {
    margin: 0px 24px;
    width: calc(100% - 48px);
    box-sizing: border-box;
}

#CashierOrderMenuRightSection {
    flex-grow: 0;
    flex-shrink: 0;
    height: 100%;
    width: 50vw;
    overflow-y: scroll;
    box-sizing: border-box;
    padding: 86px 0px;
}

#CashierMenuSectionContainer {
    width: 100%;
    padding: 0px 24px;
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: column;
    row-gap: 12px;
}

#CashierMenuCategoryTickerContainer {
    display: flex;
    gap: 12px;
    overflow-x: scroll;
    height: 76px;
    align-items: center;
    box-sizing: border-box;
    position: fixed;
    top: 52px;
    right: 0px;
    width: 50vw;
    padding: 16px 24px 0px;
    background-color: #424756;
}

#CashierMenuCategoryTickerContainer::-webkit-scrollbar {
    display: none;
}

#CashierMenuCategoryTickerContainer {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

#CashierMenuCategoryTickerContainer > button {
    display: flex;
    padding: 8px 12px;
    align-items: center;
    border-radius: 12px;
    font-size: 14px;
}

#CashierMenuCategoryTickerContainer .Active {
    border: 1px solid white;
    background-color: white;
    color: #424756;
    font-weight: 500;
}

#CashierMenuCategoryTickerContainer .Unactive {
    border: 1px solid #9BA0AD;
    color: #9BA0AD;
}

#CreateOrderPopUpMoneyContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    font-size: 12px;
}


.OrderPopUpInput {
    padding: 8px 12px;
    border-radius: 6px;
    border: 1px solid #e3e3e3;
}