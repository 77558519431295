#DriverSignInMainContainer {
  display: flex;
  flex-direction: column;
  padding: 32px 24px 24px;
  align-items: center;
  height: 100%;
  box-sizing: border-box;
}

#DriverSignInMainContainer > :nth-child(1) {
  width: 64px;
  height: 64px;
}

#DriverSignInMainContainer > :nth-child(2) {
  margin-top: 12px;
  font-size: 18px;
  font-weight: 900;
  color: #6B2B90;
  text-align: center;
}

#DriverSignInMainContainer .FormLabel {
  width: 100%;
  box-sizing: border-box;
}

#DriverSignInMainContainer .FormTextInput {
  width: 100%;
  padding: 16px;
  border: 1px solid #B1B1B1;
  box-sizing: border-box;
  border-radius: 10px;
}

#DriverSignInMainContainer .FormButton {
  width: 100%;
  padding: 16px;
}

#DriverSignInMainContainer .LoadingWidgetContainer > div {
  background-color: white;
}