#HomeMainContainer {
    display: flex;
    flex-direction: column;
    width: inherit;
    align-items: center;
    background-color: #6B2B90;
}

/* HEADER */

#HomeHeaderDesktopContainer {
    position: fixed;
    display: flex;
    column-gap: 12px;
    justify-content: center;
    padding-top: 24px;
    padding-bottom: 24px;
    box-sizing: border-box;
    z-index: 100;
    width: calc(100% - 104px);
    max-width: 1100px;
}

#HomeHeaderDesktopContainer > img {
    height: 32px;
    flex-shrink: 0;
}

#HomeHeaderDesktopContainer > div {
    flex-grow: 1;
}

#HomeHeaderDesktopContainer > button {
    font-size: 18px;
    flex-shrink: 0;
}

#HomeHeaderDesktopContainer > :last-child {
    position: fixed;
    inset: 0;
    height: 80px;
    background-color: #fc3;
    z-index: -1;
}

.HomeMenuActive {
    font-weight: 900;
    color: #6B2B90;
}

#HomeHeaderMobileContainer {
    position: fixed;
    display: flex;
    justify-content: center;
    z-index: 100;
    width: 100vw;
    padding: 16px 24px;
    box-sizing: border-box;
}

#HomeHeaderMobileContainer > img {
    height: 24px;
    flex-shrink: 0;
}

#HomeHeaderMobileContainer > div:nth-child(2) {
    flex-grow: 1;
}

#HomeHeaderMobileContainer > button {
    height: 24px;
    width: 24px;
    flex-shrink: 0;
    margin: 0;
    padding: 4px;
}

#HomeHeaderMobileContainer > button > img {
    height: 100%;
    width: 100%;
}

#HomeHeaderMobileContainer > :nth-child(4) {
    position: fixed;
    inset: 0;
    height: 56px;
    background-color: #fc3;
    z-index: -1;
}

#HomeMobileModalMenuDrawer {
    position: fixed;
    inset: 0;
    background-color: #000000c0;
}

#HomeMobileModalMenuDrawer > div {
    background-color: #fc3;
    display: flex;
    flex-direction: column;
    padding: 16px;
}

#HomeMobileModalMenuDrawer > div > button {
    padding: 12px;
    font-size: 14px;
    font-weight: 600;
}

/* END OF HEADER */


#HomeMainContainer > :nth-child(2) {
    position: fixed;
    right: 24px;
    z-index: 90;
}


/* SUMMARY */

#HomeMainContainer > :nth-child(2) > img {
    height: 72px;
}

#HomeMainContainer > div:nth-child(3) {
    display: flex;
    box-sizing: border-box;
    width: 100%;
    justify-content: center;
    z-index: 0;
}

#HomeDesktopSummaryContainer {
    display: flex;
    box-sizing: border-box;
    width: calc(100% - 104px);
    max-width: 1100px;
    height: 100vh;
}

#HomeDesktopSummaryContainer > :nth-child(1) {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
    align-items: flex-start;
    flex-shrink: 0;
}

#HomeDesktopSummaryContainer > :nth-child(1) > h1 {
    color: #383838;
    font-family: "Gordita Bold", sans-serif;
    line-height: 109%;
    font-size: 46px;
    letter-spacing: -0.02em;
    font-weight: 900;
    padding: 0;
    margin: 0;
    text-align: left;
}

#HomeDesktopSummaryContainer > :nth-child(1) > :nth-child(2) {
    height: 72px;
}

#HomeDesktopSummaryContainer > :nth-child(1) > :nth-child(3) {
    display: flex;
    flex-direction: column;
    margin-top: 12px;
}

#HomeDesktopSummaryContainer > :nth-child(1) > :nth-child(4) {
    margin-top: 24px;
    padding: 16px 32px;
    background-color: #6B2B90;
    color: #fc3;
    font-size: 22px;
    border-radius: 10px;
    font-family: "Gordita Bold", sans-serif;
    font-weight: 800;
}

#HomeDesktopSummaryContainer > :nth-child(1) > :nth-child(3) > * {
    display: flex;
    align-items: center;
    column-gap: 8px;
    color: #6B2B90;
    font-size: 32px;
    font-weight: 900;
}

#HomeDesktopSummaryContainer > :nth-child(1) > :nth-child(3) > * > img {
    height: 42px;
    width: 42px;
}

#HomeDesktopSummaryContainer > :nth-child(2) {
    flex-grow: 1;
}

#HomeDesktopSummaryContainer > :nth-child(3) {
    height: 100vh;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-shrink: 0;
    width: calc(55% - 52px);
    max-width: 550px;
}

#HomeDesktopSummaryContainer > :nth-child(3) > img {
    max-height: 60%;
}


#HomeMobileSummaryContainer {
    background-color: #fbf2e3;
    padding: 80px 24px 0px;
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: -1;
    align-items: center;
}

#HomeMobileSummaryContainer > div:nth-child(1) {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 80%;
    z-index: -1;
}

#HomeMobileSummaryContainer > h1 {
    font-size: 28px;
    text-align: center;
    color: #383838;
    font-weight: 700;
    font-family: "Gordita Bold", sans-serif;
    margin-bottom: 12px;
}

#HomeMobileSummaryContainer > img:nth-child(3) {
    height: 48px;
    width: 200px;
}

#HomeMobileSummaryContainer > div:nth-child(4) {
    display: flex;
    flex-direction: column;
    margin-top: 12px;
    align-items: center;
}

#HomeMobileSummaryContainer > div:nth-child(4) > * {
    display: flex;
    align-items: center;
    column-gap: 8px;
    color: #6B2B90;
    font-size: 20px;
    font-weight: 900;
    justify-content: center;
}

#HomeMobileSummaryContainer > div:nth-child(4) > * > img {
    height: 28px;
    width: 28px;
}

#HomeMobileSummaryContainer > button:nth-child(5) {
    background-color: #6B2B90;
    color: #fc3;
    margin: 24px;
    padding: 16px 32px;
    border-radius: 10px;
    font-family: "Gordita Bold", sans-serif;
    font-weight: 800;
    font-size: 22px;
}

#HomeMobileSummaryContainer > img:nth-child(6) {
    max-width: 400px;
    width: calc(100% - 52px);
}

/* END OF SUMMARY */


/* MISSION */

#HomeMainContainer > :nth-child(4) {
    background-color: #fbf2e3;
    display: flex;
    box-sizing: border-box;
    width: 100%;
    justify-content: center;
    background-position: bottom;
    background-repeat: no-repeat;
}

#HomeDesktopMissionContainer {
    box-sizing: border-box;
    width: calc(100% - 104px);
    max-width: 1100px;
    height: 100vh;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    padding-top: 110px;
    padding-bottom: 120px;
}

#HomeDesktopMissionContainer > :nth-child(1) {
    max-height: calc(48vh - 115px);
    box-sizing: border-box;
    display: flex;
    flex-shrink: 0;
}

#HomeDesktopMissionContainer > :nth-child(1) > img {
    flex-grow: 1;
    max-width: calc(50% - 16px);
    max-height: calc(55vh - 76px);
    flex-shrink: 0;
}

#HomeDesktopMissionContainer > :nth-child(1) > div {
    flex-grow: 1;
}

#HomeDesktopMissionContainer > :nth-child(2) {
    flex-grow: 1;
}

#HomeDesktopMissionContainer > :nth-child(3) {
    box-sizing: border-box;
    max-height: calc(40vh - 115px);
    flex-shrink: 0;
    width: 80%;
}


#HomeMobileMissionContainer {
    box-sizing: border-box;
    max-width: 400px;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    padding: 80px 24px 0;
}

#HomeMobileMissionContainer > :nth-child(1) {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    flex-shrink: 0;
    margin-bottom: 24px;
    padding-inline: 24px;
}

#HomeMobileMissionContainer > :nth-child(1) > img {
    flex-grow: 1;
    flex-shrink: 0;
    max-width: calc(100% - 48px);
}

#HomeMobileMissionContainer > :nth-child(3) {
    box-sizing: border-box;
    flex-shrink: 0;
}

/* END OF MISSION */

/* MENU */

#HomeMainContainer > :nth-child(5) {
    background-color: #f9f6f2;
    display: flex;
    box-sizing: border-box;
    width: 100%;
    justify-content: center;
}

#HomeMenuDesktopContainer {
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
}

#HomeMenuDesktopContainer > h2 {
    font-family: "Gordita Bold", sans-serif;
    font-size: 36px;
    line-height: 109%;
    text-align: center;
    color: #383838;
    margin-bottom: 32px;
    font-weight: 800;
    padding: 0px 24px;
}

#HomeMenuDesktopContainer > h2 > strong {
    color: #6B2B90;
    position: relative;
}

#HomeMenuDesktopContainer > h2 > strong::after {
    content: " ";
    position: absolute;
    width: 100%;
    height: 5px;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
    background: url(https://kokilogy-storage.s3.ap-southeast-1.amazonaws.com/website/title_stroke1.png) center no-repeat;
    background-size: contain;
}

#HomeMenuDesktopContainer .swiper-button-prev, #HomeMenuDesktopContainer .swiper-button-next {
    color: #6B2B90;
    padding: 8px;
    border-radius: 12px;
    background-color: #e4b5ff;
}

#HomeMenuCell {
    box-sizing: border-box;
    width: 100%;
    background-size: 100% 100%;
    padding: 24px;
}

#HomeMenuCell > :nth-child(1) {
    border-radius: 12px;
    margin-bottom: 12px;
    position: relative;
    overflow: hidden;
}

#HomeMenuCell > :nth-child(1) > div {
    position: absolute;
    bottom: 24px;
    left: 24px;
    right: 24px;
    background-color: #fc3;
    color: #6B2B90;
    font-size: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px;
    text-align: center;
    border-radius: 12px;
    font-weight: 700;
    opacity: 0.7;
}

#HomeMenuCell > :nth-child(1) > div p {
    text-align: center;
}

#HomeMenuCell > h2 {
    font-family: "Gordita Bold", sans-serif;
    margin-top: 8px;
    font-size: 18px;
    font-weight: 700;
    text-align: start;
    color: #6B2B90;
}

#HomeMenuCell > button {
    width: 100%;
    box-sizing: border-box;
    font-family: "Gordita Bold", sans-serif;
    margin-top: 16px;
    background-color: #6B2B90;
    color: #fc3;
    font-size: 16px;
    font-weight: 700;
    height: 40px;
    border-radius: 10px;
}

#WhyCheapDesktopContainer {
    border: 1px solid #6B2B90;
    border-radius: 30px;
    text-align: center;
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}

#WhyCheapDesktopContainer > div:nth-child(1) {
    height: 70px;
    font-family: "Gordita Bold", sans-serif;
    font-size: 40px;
    text-align: center;
    padding: 0px 42px;
    border-radius: 39px;
    background-color: #6B2B90;
    color: #fc3;
    display: flex;
    margin: -35px 0px 20px;
    width: fit-content;
    text-align: center;
    align-items: center;
    justify-content: center;
}

#WhyCheapDesktopContainer > div:nth-child(1) > h2 {
    font-family: "Gordita Bold", sans-serif;
    font-size: 32px;
    text-align: center;
    color: #fc3;
    font-weight: 700;
}

#WhyCheapDesktopContainer > div:nth-child(2) {
    display: flex;
    padding: 0px 32px 24px;
    column-gap: 16px;
}

#WhyCheapDesktopContainer > div:nth-child(2) > div {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    align-items: center;
}

#WhyCheapDesktopContainer > div:nth-child(2) > div > img {
    height: 80px;
    width: 80px;
}

#WhyCheapDesktopContainer > div:nth-child(2) > div > p {
    text-align: center;
    font-weight: 700;
    font-size: 18px;
    line-height: 110%;
}


#WhyCheapMobileContainer {
    border: 1px solid #6B2B90;
    border-radius: 30px;
    text-align: center;
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}

#WhyCheapMobileContainer > div:nth-child(1) {
    height: 40px;
    font-family: "Gordita Bold", sans-serif;
    font-size: 18px;
    text-align: center;
    padding: 0px 32px;
    border-radius: 20px;
    background-color: #6B2B90;
    color: #fc3;
    display: flex;
    margin: -20px 0px 20px;
    width: fit-content;
    text-align: center;
    align-items: center;
    justify-content: center;
}

#WhyCheapMobileContainer > div:nth-child(1) > h2 {
    font-family: "Gordita Bold", sans-serif;
    font-size: 32px;
    text-align: center;
    color: #fc3;
    font-weight: 700;
}

#WhyCheapMobileContainer > div:nth-child(2) {
    display: flex;
    padding: 0px 32px 24px;
    column-gap: 16px;
    row-gap: 16px;
    flex-flow: row wrap;
}

#WhyCheapMobileContainer > div:nth-child(2) > div {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    align-items: center;
}

#WhyCheapMobileContainer > div:nth-child(2) > div > img {
    height: 80px;
    width: 80px;
}

#WhyCheapMobileContainer > div:nth-child(2) > div > p {
    text-align: center;
    font-weight: 700;
    font-size: 18px;
    line-height: 110%;
}

/* END OF MENU */

/* QUALITY */

#HomeMainContainer > :nth-child(6) {
    background-color: #fbf2e3;
    display: flex;
    box-sizing: border-box;
    width: 100%;
    justify-content: center;
}

#HomeQualityDesktopContainer {
    box-sizing: border-box;
    width: calc(100% - 104px);
    max-width: 1100px;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
}

#HomeQualityDesktopContainer > h2 {
    font-family: "Gordita Bold", sans-serif;
    font-size: 36px;
    line-height: 109%;
    text-align: center;
    color: #383838;
    margin-bottom: 32px;
    font-weight: 800;
}

#HomeQualityDesktopContainer > h2 > strong {
    color: #6B2B90;
    position: relative;
}

#HomeQualityDesktopContainer > h2 > strong::after {
    content: " ";
    position: absolute;
    width: 100%;
    height: 5px;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
    background: url(https://kokilogy-storage.s3.ap-southeast-1.amazonaws.com/website/title_stroke1.png) center no-repeat;
    background-size: contain;
}

#HomeQualityDesktopContainer > div {
    display: flex;
    column-gap: 24px;
    align-items: flex-start;
    padding: 0px 24px;
}

#HomeQualityDesktopContainer > div > img {
    width: calc(25% - 18px);
}

#HomeQualityDesktopContainer > div > img:nth-child(1) {
    margin-top: 40px;
}

#HomeQualityDesktopContainer > div > img:nth-child(3) {
    margin-top: 90px;
}



#HomeQualityMobileContainer {
    box-sizing: border-box;
    width: 100%;
    max-width: 1100px;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    padding: 80px 24px 52px;
}

#HomeQualityMobileContainer > h2 {
    font-family: "Gordita Bold", sans-serif;
    font-size: 18px;
    line-height: 109%;
    text-align: center;
    color: #383838;
    margin-bottom: 32px;
    font-weight: 800;
}

#HomeQualityMobileContainer > h2 > strong {
    color: #6B2B90;
    position: relative;
}

#HomeQualityMobileContainer > h2 > strong::after {
    content: " ";
    position: absolute;
    width: 100%;
    height: 5px;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
    background: url(https://kokilogy-storage.s3.ap-southeast-1.amazonaws.com/website/title_stroke1.png) center no-repeat;
    background-size: contain;
}

#HomeQualityMobileContainer > div {
    display: flex;
    flex-flow: row wrap;
    column-gap: 24px;
    row-gap: 24px;
    align-items: flex-start;
}

#HomeQualityMobileContainer > div > img {
    width: calc(50% - 12px);
}

/* END OF QUALITY */

/* WHY UENA */

#HomeMainContainer > :nth-child(7) {
    background-color: #6B2B90;
    display: flex;
    box-sizing: border-box;
    width: 100%;
    justify-content: center;
    padding: 80px 24px 12px;
}

#HomeWhyUenaFoodcourtDesktopContainer {
    box-sizing: border-box;
    width: calc(100% - 104px);
    max-width: 1100px;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
}

#HomeWhyUenaFoodcourtDesktopContainer > h2 {
    font-family: "Gordita Bold", sans-serif;
    font-size: 40px;
    line-height: 109%;
    text-align: center;
    color: white;
    margin-bottom: 32px;
    font-weight: 800;
    overflow: visible;
    margin-top: 60px;
}

#HomeWhyUenaFoodcourtDesktopContainer > h2 > strong {
    color: #fc3;
    position: relative;
}

#HomeWhyUenaFoodcourtDesktopContainer > h2 > strong::after {
    content: " ";
    position: absolute;
    width: 150%;
    height: 200%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: url(https://kokilogy-storage.s3.ap-southeast-1.amazonaws.com/website/title_stroke2.png) center no-repeat;
    background-size: contain;
}

#HomeWhyUenaFoodcourtDesktopContainer > div:nth-child(2) {
    margin: 24px;
    background-color: #531B74;
    border-radius: 32px;
    display: flex;
    padding: 32px;
    column-gap: 24px;
    box-sizing: border-box;
}

#HomeWhyUenaFoodcourtDesktopContainer > div:nth-child(2) > div {
    flex-grow: 1;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    justify-content: center;
    row-gap: 24px;
}

#HomeWhyUenaFoodcourtDesktopContainer > div:nth-child(2) > div h2, #HomeWhyUenaFoodcourtDesktopContainer > div:nth-child(2) > div p {
    text-align: center;
}

#HomeWhyUenaFoodcourtDesktopContainer > div:nth-child(2) > div > h2 {
    color: #FFB3AE;
    font-family: "Gordita Bold", sans-serif;
    font-size: 40px;
    letter-spacing: -0.02em;
    margin-bottom: 24px;
    height: 80px;
    line-height: 80px;
    background: url(https://kokilogy-storage.s3.ap-southeast-1.amazonaws.com/website/title_stroke3.png) no-repeat center;
    background-size: contain;
    font-weight: 700;
}

#HomeWhyFoodcourtReasonDesktopCell {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
}

#HomeWhyFoodcourtReasonDesktopCell > h2 {
    font-family: "Gordita Bold", sans-serif;
    color: #fc3;
    font-size: 28px;
    font-weight: 600;
    display: flex;
    column-gap: 12px;
    justify-content: center;
}

#HomeWhyFoodcourtReasonDesktopCell > h2 > img {
    height: 28px;
    width: 28px;
}

#HomeWhyFoodcourtReasonDesktopCell > p {
    font-size: 20px;
    color: #FBF2E3;
}

#HomeWhyNotDriverReasonDesktopCell {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
}

#HomeWhyNotDriverReasonDesktopCell > h2 {
    font-family: "Gordita Bold", sans-serif;
    color: #FFB3AE;
    font-size: 28px;
    font-weight: 600;
}

#HomeWhyNotDriverReasonDesktopCell > p {
    font-size: 20px;
    color: #FBF2E3;
}


#HomeWhyUenaFoodcourtMobileContainer {
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
}

#HomeWhyUenaFoodcourtMobileContainer > h2 {
    font-family: "Gordita Bold", sans-serif;
    font-size: 24px;
    line-height: 109%;
    text-align: center;
    color: white;
    margin-bottom: 32px;
    font-weight: 800;
    overflow: visible;
}

#HomeWhyUenaFoodcourtMobileContainer > h2 > strong {
    color: #fc3;
    position: relative;
}

#HomeWhyUenaFoodcourtMobileContainer > h2 > strong::after {
    content: " ";
    position: absolute;
    width: 150%;
    height: 200%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: url(https://kokilogy-storage.s3.ap-southeast-1.amazonaws.com/website/title_stroke2.png) center no-repeat;
    background-size: contain;
}

#HomeWhyUenaFoodcourtMobileContainer > div:nth-child(2) {
    background-color: #531B74;
    border-radius: 32px;
    display: flex;
    padding: 32px;
    column-gap: 24px;
    box-sizing: border-box;
}

#HomeWhyUenaFoodcourtMobileContainer > div:nth-child(2) > div {
    flex-grow: 1;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    justify-content: center;
    row-gap: 24px;
}

#HomeWhyUenaFoodcourtMobileContainer > div:nth-child(2) > div h2, #HomeWhyUenaFoodcourtMobileContainer > div:nth-child(2) > div p {
    text-align: center;
}

#HomeWhyUenaFoodcourtMobileContainer > div:nth-child(2) > div > h2 {
    color: #FFB3AE;
    font-family: "Gordita Bold", sans-serif;
    font-size: 18px;
    letter-spacing: -0.02em;
    margin-bottom: 24px;
    height: 42px;
    line-height: 42px;
    background: url(https://kokilogy-storage.s3.ap-southeast-1.amazonaws.com/website/title_stroke3.png) no-repeat center;
    background-size: contain;
    font-weight: 700;
}

#HomeWhyFoodcourtReasonMobileCell {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
}

#HomeWhyFoodcourtReasonMobileCell > h2 {
    font-family: "Gordita Bold", sans-serif;
    color: #fc3;
    font-size: 15px;
    font-weight: 600;
    display: flex;
    column-gap: 12px;
    justify-content: center;
}

#HomeWhyFoodcourtReasonMobileCell > h2 > img {
    height: 20px;
    width: 20px;
}

#HomeWhyFoodcourtReasonMobileCell > p {
    font-size: 12px;
    color: #FBF2E3;
}

#HomeWhyNotDriverReasonMobileCell {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
}

#HomeWhyNotDriverReasonMobileCell > h2 {
    font-family: "Gordita Bold", sans-serif;
    color: #FFB3AE;
    font-size: 15px;
    font-weight: 600;
}

#HomeWhyNotDriverReasonMobileCell > p {
    font-size: 12px;
    color: #FBF2E3;
}

/* END OF WHY UENA */

/* HOW TO ORDER */

#HomeMainContainer > :nth-child(8) {
    background-color: #6B2B90;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    width: 100%;
    justify-content: center;
    align-items: center;
}

#HomeHowToOrderDesktopContainer {
    box-sizing: border-box;
    width: calc(100% - 104px);
    max-width: 1100px;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    flex-grow: 1;
    margin-top: 40px;
}

#HomeHowToOrderDesktopContainer > h2 {
    font-family: "Gordita Bold", sans-serif;
    font-size: 36px;
    line-height: 109%;
    text-align: center;
    color: white;
    margin-bottom: 50px;
    font-weight: 800;
}

#HomeHowToOrderDesktopContainer > h2 > strong {
    color: #fc3;
    position: relative;
}

#HomeHowToOrderDesktopContainer > h2 > strong::after {
    content: " ";
    position: absolute;
    width: 100%;
    height: 5px;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
    background: url(https://kokilogy-storage.s3.ap-southeast-1.amazonaws.com/website/title_stroke1.png) center no-repeat;
    background-size: contain;
}

#HomeHowToOrderDesktopContainer > div:nth-child(2) {
    display: flex;
    row-gap: 12px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#HomeHowToOrderDesktopContainer > div:nth-child(2) > a {
    display: flex;
    align-items: center;
    width: 60%;
    box-sizing: border-box;
}

#HomeHowToOrderDesktopContainer > div:nth-child(2) img {
    height: 90px;
    width: 90px;
    margin-right: 24px;
}

#HomeHowToOrderDesktopContainer > div:nth-child(2) p {
    color: #F4BBF5;
    font-family: "Gordita Bold", sans-serif;
    font-size: 18px;
    font-weight: 700;
}

#HomeFooterDesktopContainer {
    flex-shrink: 0;
    background-color: #531B74;
    display: flex;
    justify-content: center;
    width: 100%;
}

#HomeFooterDesktopContainer > div {
    box-sizing: border-box;
    width: calc(100% - 104px);
    max-width: 1100px;
    align-items: center;
    display: flex;
    padding: 24px 0px;
}

#HomeFooterDesktopContainer > div > * {
    flex-shrink: 0;
}

#HomeFooterDesktopContainer > div > :nth-child(1) {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    align-items: flex-start;
    font-size: 12px;
    line-height: 140%;
    color: #fc3;
    font-family: "Gordita Bold", sans-serif;
}

#HomeFooterDesktopContainer > div > :nth-child(1) > img {
    height: 60px;
}

#HomeFooterDesktopContainer > div > :nth-child(2) {
    flex-grow: 1;
}

#HomeFooterDesktopContainer > div > :nth-child(3) {
    font-size: 14px;
    font-family: "Gordita Bold", sans-serif;
    color: white;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
}

#HomeFooterDesktopContainer > div > :nth-child(3) > div {
    display: flex;
    column-gap: 40px;
}

#HomeFooterFollowUsDesktopContainer {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
}

#HomeFooterFollowUsDesktopContainer > :nth-child(2) { 
    display: flex;
    column-gap: 16px;
}

#HomeFooterFollowUsDesktopContainer > :nth-child(2) > a > img {
    height: 24px;
}

#HomeFooterDownloadDesktopContainer {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
}

#HomeFooterDownloadDesktopContainer > :nth-child(2) { 
    display: flex;
    column-gap: 16px;
    row-gap: 8px;
}

#HomeFooterDownloadDesktopContainer > :nth-child(2) > a > img {
    height: 32px;
}




#HomeHowToOrderMobileContainer {
    box-sizing: border-box;
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    flex-grow: 1;
    margin-top: 32px;
    margin-bottom: 52px;
}

#HomeHowToOrderMobileContainer > h2 {
    font-family: "Gordita Bold", sans-serif;
    font-size: 18px;
    line-height: 109%;
    text-align: center;
    color: white;
    margin-bottom: 32px;
    font-weight: 800;
}

#HomeHowToOrderMobileContainer > h2 > strong {
    color: #fc3;
    position: relative;
}

#HomeHowToOrderMobileContainer > h2 > strong::after {
    content: " ";
    position: absolute;
    width: 100%;
    height: 5px;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
    background: url(https://kokilogy-storage.s3.ap-southeast-1.amazonaws.com/website/title_stroke1.png) center no-repeat;
    background-size: contain;
}

#HomeHowToOrderMobileContainer > div:nth-child(2) {
    display: flex;
    row-gap: 12px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#HomeHowToOrderMobileContainer > div:nth-child(2) > a {
    display: flex;
    align-items: center;
    width: 60%;
    box-sizing: border-box;
}

#HomeHowToOrderMobileContainer > div:nth-child(2) img {
    height: 64px;
    width: 64px;
    margin-right: 24px;
}

#HomeHowToOrderMobileContainer > div:nth-child(2) p {
    color: #F4BBF5;
    font-family: "Gordita Bold", sans-serif;
    font-size: 12px;
    font-weight: 700;
}

#HomeFooterMobileContainer {
    flex-shrink: 0;
    background-color: #531B74;
    display: flex;
    justify-content: center;
    width: 100%;
}

#HomeFooterMobileContainer > div {
    box-sizing: border-box;
    width: 100%;
    align-items: center;
    display: flex;
    padding: 24px;
}

#HomeFooterMobileContainer > div > * {
    flex-shrink: 0;
}

#HomeFooterMobileContainer > div > :nth-child(1) {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    align-items: flex-start;
    font-size: 12px;
    line-height: 140%;
    color: #fc3;
    font-family: "Gordita Bold", sans-serif;
}

#HomeFooterMobileContainer > div > :nth-child(1) > img {
    height: 60px;
}

#HomeFooterMobileContainer > div > :nth-child(2) {
    flex-grow: 1;
}

#HomeFooterMobileContainer > div > :nth-child(3) {
    font-size: 14px;
    font-family: "Gordita Bold", sans-serif;
    color: white;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
}

#HomeFooterMobileContainer > div > :nth-child(3) > div {
    display: flex;
    column-gap: 18px;
}

#HomeFooterFollowUsMobileContainer {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
}

#HomeFooterFollowUsMobileContainer > :nth-child(2) { 
    display: flex;
    column-gap: 12px;
}

#HomeFooterFollowUsMobileContainer > :nth-child(2) > a > img {
    height: 24px;
}

#HomeFooterDownloadMobileContainer {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
}

#HomeFooterDownloadMobileContainer > :nth-child(2) { 
    display: flex;
    flex-direction: column;
    column-gap: 16px;
    row-gap: 8px;
}

#HomeFooterDownloadMobileContainer > :nth-child(2) > a > img {
    height: 32px;
}

#DeleteAccountFormContainer{
    margin: 120px auto;
    background-color: #fbf2e3;
    width: 50%;
    border-radius: 8px;
    padding: 32px;
}


/* END OF HOW TO ORDER */