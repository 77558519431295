#ErrorStateContainer {
    position: fixed;
    inset: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px 32px;
    background-color: white;
    z-index: 1000;
}

#ErrorStateContainer > :nth-child(1) {
    background-color: red;
    width: 42px;
    height: 42px;
    border-radius: 21px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-weight: bold;
    font-size: 24px;
    margin-bottom: 24px;
}

#ErrorStateContainer > :nth-child(2) {
    font-size: 14px;
    font-weight: 600;
    width: 100%;
    text-align: center;
    margin-bottom: 24px;
}

#ErrorStateContainer > :nth-child(3) {
    width: 100%;
}