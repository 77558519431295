.App {
  text-align: center;
  height: calc(var(--vh, 1vh) * 100);
  width: 100%;
}

h1 {
  padding: 0;
  margin: 0;
}

h2 {
  padding: 0;
  margin: 0;
}

.FormTitle {
  font-size: 32px;
  font-weight: 700;
  padding: 8px 0px;
  text-align: center;
  color: whitesmoke;
}

.FormLabel {
  font-size: 18px;
  font-weight: 700;
  display: flex;
  align-items: center;
}

.FormTextInput {
  padding: 8px 12px;
  border-radius: 6px;
  border: none;
}

button.FormButton {
  padding: 8px;
  border-radius: 10px;
  font-weight: 600;
  border: none;
  background-color: white;
  box-sizing: border-box;
}

p {
  padding: 0px;
  margin: 0px;
  text-align: left;
  font-family: inherit;
}

button {
  background-color: transparent;
  border: none;
  font-family: inherit !important;
}

.FormTableContainer {
  max-height: 30vh;
  overflow-y: scroll;
}

.ItemTableContainer {
  margin-bottom: 5vh;
  max-height: 50vh;
  overflow-y: scroll;
}

@media screen and (max-height: 700px) {
  .FormTableContainer {
    max-height: 250px;
    overflow-y: scroll;
  }

  .ItemTableContainer {
    margin-bottom: 5vh;
    max-height: 350px;
    overflow-y: scroll;
  }
}

@media screen and (max-width: 700px) {
  .FormTableContainer {
    width: 100vw;
    overflow-x: scroll;  
    margin: 0 auto;
    transform: scale(0.9);
  }

  .FormTableContainer * {
    font-size: 10px;
  }

  .ItemTableContainer {
    width: 100vw;
    margin: 0 auto;
    transform: scale(0.9);
    overflow-x: scroll;
  }

  .ItemTableContainer * {
    font-size: 10px;
  }
}