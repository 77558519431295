#MobileQrisContainer {
    padding: 64px 32px 104px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    height: 100vh;
    overflow: scroll;
    box-sizing: border-box;
}

#MobileQrisContainer > :nth-child(1) {
    font-size: 20px;
    font-weight: bold;
    color: #6A2C8D;
    margin-bottom: 24px;
    box-sizing: border-box;
}

#MobileQrisContainer > :nth-child(2) {
    font-size: 14px;
    color: #6A2C8D;
    margin-bottom: 4px;
}

#MobileQrisContainer > :nth-child(3) {
    font-size: 40px;
    font-weight: bold;
    margin-bottom: 4px;
}

#MobileQrisContainer > :nth-child(4) {
    font-size: 12px;
    color: #C5C5C5;
    margin-bottom: 24px;
}

#MobileQrisContainer p {
    text-align: center;
}

#MobileQrisContainer canvas {
    object-fit: fill;
    width: 70vw;
    height: 70vw;
    margin-bottom: 24px;
    box-sizing: border-box;
}

#MobileQrisExpiredContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 0px 32px;
}

#MobileQrisExpiredContainer > p {
    text-align: center;
    color: #6A2C8D;
    font-size: 16px;
    line-height: 1.4;
}

#MobileQrisExpiredContainer > div {
    background-color: red;
    width: 42px;
    height: 42px;
    border-radius: 21px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-weight: bold;
    font-size: 24px;
    margin-bottom: 24px;
}

#MobileQrisInstructionContainer {
    background-color: #FFEEEE;
    border-radius: 8px;
    padding: 16px;
    display: flex;
    column-gap: 12px;
}

#MobileQrisInstructionContainer > :nth-child(1) {
    background-color: #F38484;
    height: 16px;
    width: 16px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    flex-shrink: 0;
    font-size: 12px;
    font-weight: 700;
}

#MobileQrisInstructionContainer > p {
    text-align: left;
    flex-grow: 1;
    font-size: 12px;
    font-weight: 700;
}

#MobileQrisBottomSection {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 16px 24px;
    background-color: white;
}

#MobileQrisBottomSection > .FormButton {
    width: 100%;
    height: 50px;
}

#MobileQrisBottomSection .LoadingWidgetContainer > div {
    background-color: white;
}