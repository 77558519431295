#DriverOrderDetail {
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;
}

#DriverOrderDetailContentContainer {
    padding: 92px 24px 132px;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
}

#DriverOrderDetailContentContainer > * {
    display: block;
    text-align: left;
}

#DriverOrderDetailContentContainer ul {
    margin: 0;
    padding-left: 16px;
}

#DriverDetailDetailBottomContainer {
    background-color: white;
    padding: 16px;
    display: flex;
    flex-direction: column;
    row-gap: 12px;
    box-shadow: 0px -6px 10px 0px #00000011;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
}

#DriverDetailDetailBottomContainer > :nth-child(2) {
    display: flex;
    column-gap: 12px;
}

#DriverDetailDetailBottomContainer > :nth-child(2) > * {
    width: calc(50% - 6px);
}

#DriverDetailConfirmationPopUp {
    display: flex;
    flex-direction: column;
}