#SoScreenContainer {
    position: relative;
    width: inherit;
    height: 100vh;
    color: white;
    display: flex;
    flex-direction: column;
}

#SoScreenContainer > :nth-child(1) {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #424756;
    flex-grow: 1;
}

#SoHistoryContainer {
    min-width: 80%;
}

#SoItemsContainer {
    margin-top: 50px;
    min-width: 80%;
}

#LocationSelectionContainer {
    padding-top: 150px;
    width: inherit;
    min-height: 100vh;
    background-color: #424756;
    display: flex;
    justify-content: center;
}

.SoTable {
    width: 100%;
    font-size: 1.1em;
}

.SoTable thead {
    background-color: slategray;
    font-weight: bold;
}

.SoTable thead > tr > * {
    padding: 8px 10px;
}

.SoTable tbody {
    background-color: #FEFEFE;
    color: #000;
    font-size: 1rem;
}

.SoTable tbody > tr > * {
    padding: 10px 5px;
}

.FormSelected {
    background-color: #777777;
}