#FranchiseLandingContainer > img:nth-child(1) {
    height: 100vh;
    width: 100vw;
    object-fit: cover;
    position: fixed;
    inset: 0;
}

#FranchiseLandingContainer > img:nth-child(2) {
    height: calc(100vh - 160px);
    width: calc(100vw - 90px);
    object-fit: contain;
    inset: 0;
    margin: 80px 45px;
    position: fixed;
    box-sizing: border-box;
}

#FranchiseLandingAppBar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 60px;
    display: flex;
    margin: 16px 42px;
    justify-content: center;
    align-items: center;
}

#FranchiseLandingAppBar > :nth-child(1) > img {
    height: 28px;
    object-fit: contain;
}

#FranchiseLandingAppBar > :nth-child(2) {
    flex-grow: 1;
}

#FranchiseLandingAppBar > :nth-child(3) {
    background-color: #6B2B90;
    display: flex;
    padding: 8px 16px;
    justify-content: center;
    height: 40px !important;
    column-gap: 12px;
    border-radius: 12px;
    text-decoration: none;
    color: white;
    box-sizing: border-box;
    align-items: center;
}

#FranchiseLandingAppBar > :nth-child(3) > img {
    height: 24px;
    width: 24px;
}

#FranchiseLandingAppBar > :nth-child(3) > p {
    font-weight: bold;
}

#FranchiseIGContainer {
    position: fixed;
    bottom: 24px;
    right: 42px;
    display: flex;
    align-items: center;
    text-decoration: none;
    color: black;
}

#FranchiseInfoContainer {
    left: 0;
    right: 0;
    margin-left: auto;
    bottom: 24px;
    margin-right: auto;
    position: fixed;
    row-gap: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

#FranchiseInfoContainer > :nth-child(1) {
    font-size: 20px;
    font-weight: bold;
    color: #6D318E;
    text-shadow: 1px 1px white, 1px -1px white, -1px 1px white, -1px -1px white, 0 0 1px white;
    text-align: center;
}

#FranchiseInfoContainer > :nth-child(2) {
    padding: 10px 24px;
    background-color: white;
    border: 1px solid #6B2B90;
    border-radius: 12px;
    display: flex;
    column-gap: 12px;
    font-size: 18px;
    font-weight: bold;
    color: #6B2B90;
    text-decoration: none;
}

#FranchiseInfoContainer > :nth-child(2) > :nth-child(1) {
    height: 24px;
    width: 24px;
}

#FranchiseLandingMobileContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
}

#FranchiseLandingMobileContainer > :nth-child(1), 
#FranchiseLandingMobileContainer > :nth-child(2) {
    width: 100%;
}

#FranchiseLandingMobileContainer > :nth-child(3) {
    position: absolute;
    top: 24px;
    width: 35%;
    left: 32.5%;
}

#FranchiseLandingMobileContainer > :nth-child(3) > * {
    width: 100%;
}

#FranchiseLandingMobileContainer > :nth-child(4) {
    position: absolute;
    bottom: 8vw;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}

#FranchiseLandingMobileContainer > :nth-child(4) > :nth-child(1) {
    font-size: 6vw;
    color: white;
    font-weight: bold;
}

#FranchiseLandingMobileContainer > :nth-child(4) > :nth-child(2) {
    font-weight: bold;
    font-size: 5.5vw;
    text-decoration: none;
    background-color: white;
    color: #6B2B90;
    padding: 2vw 3.5vw;
    border-radius: 2.5vw;
    display: flex;
    align-items: center;
    column-gap: 2vw;
    margin-top: 2.5vw;
    margin-bottom: 5vw;
}

#FranchiseLandingMobileContainer > :nth-child(4) > :nth-child(2) > :nth-child(1) {
    width: 6vw;
    height: 6vw;
}

#FranchiseLandingMobileContainer > :nth-child(4) > :nth-child(3) {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: white;
    font-size: 5vw;
}

#FranchiseLandingMobileContainer > :nth-child(4) > :nth-child(3) > img {
    height: 6vw;
    width: 6vw;
    margin-right: 1vw;
}