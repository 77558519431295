#Scaffold {
    position: relative;
    width: inherit;
}

#ScaffoldHeader {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    background-color: white;
    flex-shrink: 0;
    text-align: left;
    padding: 0px 24px;
    height: 52px;
    display: flex;
    align-items: center;
    z-index: 100;
}

#ScaffoldHeader > :nth-child(1) {
    height: 24px;
}

#SnackbarContainer {
    position: absolute;
    top: -200px;
    left: 16px;
    right: 16px;
    border-radius: 10px;
    font-size: 14px;
    font-weight: 600;
    color: white;
    padding: 12px;
    animation-name: SnackbarAnimation;
    animation-duration: 6s;
    z-index: 99;
}

@keyframes SnackbarAnimation {
    0% {
        top: -200px;
    }
    10% {
        top: 12px
    }
    90% {
        top: 12px;
    }
    100% {
        top: -200px;
    }
}

#NotFoundContainer {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #6B2C8F;
    color: white;
}

#NotFoundContainer > :nth-child(1) {
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 16px;
    text-align: center;
}

#NotFoundContainer > :nth-child(2) {
    font-size: 16px;
    margin-bottom: 16px;
    text-align: center;
    line-height: 1.4;
}

#PopUpBackground {
    width: 100%;
    height: 100%;
    background-color: #00000077;
    z-index: 1000;
    position: fixed;
    inset: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

#PopUpContainer {
    width: 100%;
    box-sizing: border-box;
    background-color: white;
    margin: 0px 24px;
    padding: 16px;
    border-radius: 10px;
}