.LoadingWidgetContainer {
    display: flex;
    justify-content: center;
}
  
.LoadingWidgetContainer > div {
    width: 12px;
    height: 12px;
    margin: 3px 3px;
    border-radius: 50%;
    opacity: 0.2;
    transform: scale(0.3, 0.3);
    animation: loading-animation 1s infinite;
    image-rendering: smooth;
    background-color: #FFFFFF;
}

/* .LoadingWidgetDarkTheme > div {
    background-color: #FFFFFF;
}

.LoadingWidgetLightTheme > div {
    background-color: #35373B;
} */

@keyframes loading-animation {
    0% {
        opacity: 0.2;
        transform: scale(0.3, 0.3);
    }

    40% {
        opacity: 1;
        transform: scale(1, 1);
    }

    80% {
        opacity: 0.2;
        transform: scale(0.3, 0.3);
    }

    100% {
        opacity: 0.2;
        transform: scale(0.3, 0.3);
    }
}
  
.LoadingWidgetContainer > div:nth-child(2) {
    animation-delay: 0.15s;
}
  
.LoadingWidgetContainer > div:nth-child(3) {
    animation-delay: 0.3s;
}