#ServiceAreasForm {
    position: absolute;
    top: 72px;
    left: 24px;
    display: flex;
    flex-direction: column;
    row-gap: 12px;
    background-color: #00000091;
    padding: 16px 20px;
    border-radius: 12px;
}

#ServiceAreasForm p {
    color: white;
}

#ServiceAreasLinksTextFieldArea {
    width: 30vw;
    max-width: 400px;
    min-width: 300px;
    display: flex;
    column-gap: 12px;
}

#ServiceAreasLinksTextFieldArea .FormTextInput {
    flex-grow: 1;
    min-width: 80px;
}

#ServiceAreasLinksTextFieldArea button {
    flex-shrink: 0;
}

#ServiceAreaInfo {
    position: absolute;
    top: 72px;
    right: 24px;
    padding: 16px 20px;
    border-radius: 12px;
    color: white;
    display: flex;
    flex-direction: column;
    row-gap: 8px;
}

#ServiceAreaInfo :nth-child(1) {
    font-size: 14px;
}

#ServiceAreaInfo :nth-child(2) {
    font-size: 16px;
    font-weight: 700;
}

#ServiceAreaInfo :nth-child(3) {
    font-size: 14px;
}

