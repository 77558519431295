#RadioFormContainer {
    display: flex;
    flex-flow: row wrap;
    gap: 8px;
}

#RadioFormContainer > button {
    display: flex;
    padding: 6px;
    align-items: center;
    border-radius: 12px;
    font-size: 14px;
}

.RadioFormActiveButton {
    border: 1px solid white;
    background-color: white;
    color: #424756;
    font-weight: 500;
}

.RadioButtonDisableButton {
    border: 1px solid #9BA0AD;
    color: #9BA0AD;
}