#CheckFormContainer {
    display: flex;
    align-items: center;
}

#CheckFormContainer button {
    padding: 6px;
    height: 28px;
    width: 32px;
}

#CheckFormContainer img {
    height: 16px;
    width: 20px;
}

#CheckFormContainer p {
    font-size: 14px;
}