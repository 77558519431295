.UenaLogoLoadingWidgetContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
  
.UenaLogoLoadingWidgetContainer img {
    height: 24px;
    width: 20px;
    opacity: 0;
    transform: scale(0.3, 0.3);
    animation: logo-loading-animation 4s infinite;
    image-rendering: smooth;
}

@keyframes logo-loading-animation {
    0% {
        opacity: 0;
        transform: scale(0.3, 0.3) translateY(0);
    }

    5%, 12.5% {
        transform: translateY(0);
    }

    10% {
        transform: translateY(-12px);
    }

    25% {
        transform: translateY(-6px);
    }

    30% {
        opacity: 1;
        transform: scale(1, 1) translateY(0);
    }

    70% {
        opacity: 1;
        transform: scale(1, 1) translateY(0);
    }

    75% {
        opacity: 1;
        transform: translateY(-6px);
    }

    85%, 77.5% {
        opacity: 1;
        transform: translateY(0);
    }

    80% {
        opacity: 1;
        transform: translateY(-12px);
    }

    90% {
        opacity: 0;
        transform: scale(0.3, 0.3);
    }
}

.UenaLogoLoadingWidgetContainer > div {
    display: flex;
    margin-top: 16px;
    margin-bottom: 12px;
}
  
.UenaLogoLoadingWidgetContainer > div > img:nth-child(1) {
    animation-delay: 0.1s;
}
  
.UenaLogoLoadingWidgetContainer > div > img:nth-child(2) {
    animation-delay: 0.2s;
}

.UenaLogoLoadingWidgetContainer > div > img:nth-child(3) {
    animation-delay: 0.3s;
}

.UenaLogoLoadingWidgetContainer > div > img:nth-child(4) {
    animation-delay: 0.4s;
}

.UenaLogoLoadingWidgetContainer p {
    font-size: 6px;
    color: #FFCC33;
    opacity: 0;
    transform: scale(0.3, 0.3);
    animation: logo-text-loading-animation 4s infinite;
    image-rendering: smooth;
    animation-delay: 0.5s;
}

@keyframes logo-text-loading-animation {
    0% {
        opacity: 0;
        transform: scale(0.3, 0.3);
    }

    30% {
        opacity: 1;
        transform: scale(1, 1);
    }

    70% {
        opacity: 1;
        transform: scale(1, 1);
    }

    90% {
        opacity: 0;
        transform: scale(0.3, 0.3);
    }
}