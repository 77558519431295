#CompletionOrderScreen {
    background-color: white;
    width: 100%;
    height: 100%;
    padding: 52px 24px 24px;
    display: flex;
    flex-direction: column;
    column-gap: 12px;
    box-sizing: border-box;
    overflow: scroll;
}

#CompletionOrderScreen table {
    box-sizing: border-box;
    position: relative;
}

#CompletionOrderScreen table,
#CompletionOrderScreen td,
#CompletionOrderScreen th {
    border-collapse: collapse;
    border: 1px solid black;
}

#CompletionOrderScreen tr:nth-child(1) {
    position: sticky;
    top: 0;
}

#CompletionOrderScreen td {
    padding-block: 4px;
}

#CompletionOrderScreen tr:nth-child(odd) {
    background-color: #a5fff6;
}

#CompletionOrderScreen tr:nth-child(even) {
    background-color: yellow;
}

#CompletionOrderScreen tr:nth-child(1) {
    background-color: white;
}

#CompletionOrderScreen  {
    font-size: 16px;
    color: black;
}