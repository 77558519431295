#AdhocCrmScreenContainer {
    height: 100%;
}

#AdhocCrmScreenMainContentContainer {
    background-color: #424756;
    width: 100%;
    min-height: 100%;
    padding: 84px 48px 84px;
    column-gap: 12px;
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    row-gap: 12px;
    align-items: center;
}

#AdhocCrmScreenMainContentContainer input[type="file" i] {
    color: white;
    padding: 8px 0px;
}

#AdhocCrmScreenMainContentContainer p {
    color: white;
}

#AdhocCrmScreenMainContentContainer input, #AdhocCrmScreenMainContentContainer textarea  {
    box-sizing: border-box;
    padding: 8px 12px;
    border-radius: 6px;
    border: none;
    font: inherit;
}

#AdhocCrmScreenMainContentContainer > * {
    max-width: 450px;
    width: 100%;
}

#AdhocBottomSection {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    box-sizing: border-box;
    flex-shrink: 0;
    width: inherit;
    background-color: #6B2C8F;
    padding: 12px 40px;
    z-index: 100;
    height: 60px;
    display: flex;
    flex-direction: row-reverse;
}

#AdhocBottomSection > button {
    background-color: #FFCC33;
    color: #6B2C8F;
}

#AdhocCrmAttachedImageContainer {
    display: flex;
    
}

#AdhocCrmAttachedImageContainer > img {
    max-width: 80px;
}