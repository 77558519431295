#ReferralDisplayContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
    z-index: 100;
    background-color: white;
}

#ReferralDisplayContainer > img:nth-child(1) {
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    width: 100vw;
    /* z-index: 100; */
    background-color: white;
    object-fit: contain;
    width: 100vw;
}

#ReferralCodeContainer {
    z-index: 100;
    border-radius: 10px;
    display: flex;
    align-items: center;
    padding-left: 20px;
    padding-right: 12px;
    margin-left: 32px;
    margin-right: 32px;
    height: 96px;
    background-color: white;
    box-shadow: 0px 6px 10px 0px #00000022;
}

#ReferralCodeContainer > div {
    display: flex;
    width: 100%;
    align-items: center;
}

#ReferralCodeContainer > div > div:nth-child(1) {
    display: flex;
    flex-direction: column;
    row-gap: 6px;
    flex-grow: 1;
}

#ReferralCodeContainer > div > div:nth-child(1) > :nth-child(1) {
    font-size: 16px;
    font-weight: 700;
}

#ReferralCodeContainer > div > div:nth-child(1) > :nth-child(2) {
    font-size: 28px;
    font-weight: 800;
    color: #6B2D8D;
}

#ReferralCodeContainer > div > button:nth-child(2) {
    height: 42px;
    width: 42px;
    padding: 0;
}

#ReferralTabContainer {
    padding: 8px 24px 0px;
}

#ReferralTabContainer > div:nth-child(1) {
    display: flex;
}

.ReferralTabButton {
    padding-block: 16px;
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    position: relative;
    padding: 16px 0px;
}

.ReferralTabButton > div {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
}

.ReferralTabButton.Active {
    color: #161616;
    font-weight: 700;
}

.ReferralTabButton.Unactive {
    color: #888888;
}

.ReferralTabButton > p {
    text-align: center;
}

.ReferralTabButton.Active > div {
    height: 3px;
    background-color: #6B2D8D;
}

.ReferralTabButton.Unactive > div {
    height: 1px;
    background-color: #E0E0E0;
}

#ReferralStepsContainer {
    padding: 16px 0px 84px;
    display: flex;
    flex-flow: row wrap;
    row-gap: 12px;
    column-gap: 12px;
}

.ReferralLoadingItemContainer {
    background-color: white;
    border-radius: 10px;
    padding: 4px 12px 12px;
    display: flex;
    flex-direction: column;
    position: relative;
    box-sizing: border-box;
    box-shadow: 0px 0px 10px 0px #00000022;
}

.ReferralLoadingItemContainer > div {
    display: flex;
    justify-content: center;
    align-items: center;
}

.ReferralItemContainer {
    background-color: white;
    border-radius: 10px;
    padding: 4px 12px 12px;
    display: flex;
    flex-direction: column;
    position: relative;
    box-sizing: border-box;
    box-shadow: 0px 0px 10px 0px #00000022;
}

.ReferralItemContainer > :nth-child(1) {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #6B2D8D;
    color: white;
    font-size: 10px;
    font-weight: 600;
    height: 20px;
    width: 20px;
    border-radius: 10px;
    position: absolute;
    top: 12px;
    left: 12px;
}

.ReferralItemContainer > p {
    font-size: 12px;
    font-weight: 600;
    text-align: center;
}

#ReferralReferredContainer {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    padding: 16px 0px 86px;
}

.ReferralReferredLoadingItem {
    display: flex;
    padding: 16px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 0px 10px 0px #00000022;
    align-items: center;
}

.ReferralReferredLoadingItem > :nth-child(1) {
    height: 21.5px;
    width: 65%;
    flex-grow: 1;
}

.ReferralReferredItem {
    display: flex;
    padding: 16px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 0px 10px 0px #00000022;
    align-items: center;
}

.ReferralReferredItem > :nth-child(1) {
    font-size: 16px;
    font-weight: 700;
    flex-grow: 1;
}

.ReferralReferredItem > :nth-child(2) {
    width: 20px;
    height: 20px;
    margin-right: 4px;
    margin-left: 8px;
    flex-shrink: 0;
}

.ReferralReferredItem > :nth-child(3) {
    font-size: 14px;
    font-weight: 700;
    color: #D8A40B;
    flex-shrink: 0;
} 

#ReferralReferredNoItem {
    padding: 42px 0px;
}

#ReferralReferredNoItem > img {
    width: 70%;
    height: 70%;
}

#ReferralReferredNoItem > p {
    font-size: 18px;
    font-weight: 700;
    text-align: center;
}

#ReferralShareButton {
    width: 52px;
    height: 52px;
    background-color: #6B2B90;
    border-radius: 26px;
    position: fixed;
    bottom: 16px;
    right: 16px;
}