#DriverOrderListMainContainer {
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    padding: 0px;
    display: flex;
    flex-direction: column;
    position: relative;
}

#DriverAppBar {
    padding: 8px 24px;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 6px 10px 0px #00000011;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: white;
    z-index: 100;
}

#DriverAppBar > :nth-child(1) {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 8px 0;
}

#DriverOrderListItemsContainer {
    display: flex;
    flex-direction: column;
    padding: 16px 24px;
    width: 100%;
    box-sizing: border-box;
    height: inherit;
}

#DriverOrderListEmptyContainer {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

#DriverOrderListEmptyContainer p {
    margin-top: 16px;
    font-size: 12px;
    font-weight: bold;
    text-align: center;
    color: #3e3e3e;
}

.DriverOrderListItemContent {
    display: flex;
    flex-direction: column;
    row-gap: 2px;
    flex-grow: 1;
}

.DriverOrderListItemContent p {
    max-lines: 1;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2; 
    -webkit-box-orient: vertical;
    font-size: 12px;
}

.OrderSummaryCellContainer {
    margin: 8px 0px;
    padding: 16px;
    border-radius: 10px;
    box-shadow: 0px 0px 12px 0px #00000011;
    display: flex;
}

.OrderSummaryCellContainer > div {
    display: flex;
    flex-direction: column;
}

.OrderSummaryCellContainer > button {
    margin: 0px 0px 0px 8px;
    padding: 0px;
}
#DriverOrderListBottomContainer {
    margin: 0px;
    padding: 16px;
    box-shadow: 0px -6px 10px 0px #00000011;
    display: flex;
    flex-direction: column;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    box-sizing: border-box;
    background-color: white;
}

#DriverOrderListBottomContainer > * {
    width: 100%;
}

#DriverOrderListInputOrderIdContainer {
    margin-top: 12px;
    display: flex;
}

#DriverOrderListInputOrderIdContainer > .FormTextInput {
    border: 1px solid #B1B1B1;
    flex-grow: 1;
    margin-right: 12px;
    height: 36px;
    box-sizing: border-box;
}

#DriverOrderListInputOrderIdContainer > button {
    height: 36px;
    width: 36px;
    border-radius: 50%;
    background-color: #F9EEFF;
    color: #6E2F93;
    font-size: 18px;
    font-weight: 900;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 12px;
}